import React, { useState, useEffect, useRef } from "react"
import SponsorCard from "./SponsorCard"
import "bootstrap/dist/css/bootstrap.min.css"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(250px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;
const Wraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  &.animate {
    animation: fade-up 2s;
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
`

const Text = styled.h1`
  /* font-family: "Archivo", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 150.3%;
  display: flex;
  letter-spacing: 0.055em;
  color: #303030;
  @media (max-width: 700px) {
    font-size: 20px;
    width: 100%;
  }
`

const Bgtext = styled.h1`
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 18vw;
  line-height: auto;

  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #0b9299;
  opacity: 0.05;
  z-index: -1;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
`

const Colu = styled(Col)`
  position: relative;
  // top: -100px;
  margin-bottom: 3%;
  @media (max-width: 700px) {
    width: 100%;
  }
`

const Div = styled.div`
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`
// TODO: agregar imagenes faltantes de breca y alicorp

function SponsorsList({data}) {


  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 1300) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
      <Row style={{ padding: "20px 0px 0px 0px", margin: "0px" }}>
        {/* <Div>
          <Bgtext
            data-sal="slide-up"
            data-sal-delay="100"
            data-sal-duration="1000"
            data-sal-easing="ease-in-out"
          >
            SPONSORS
          </Bgtext>
        </Div> */}
        <Colu xs="12" sm={{ size: 10, offset: 1 }}>

          <Wraper
          className={show ? "animate" : ""}
          >

            {data.map((item) => {
              return(
                <SponsorCard
              image={item.fields.file.url}
              name={item.fields.file.filename}
            />
              )
            })}
           
          </Wraper>
        </Colu>
      </Row>
    </>
  )
}

export default SponsorsList
