import React, { useState, useEffect, useRef } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { CardImg, CardBody, CardText } from "reactstrap"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(200px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const CardStyled = styled.div`
  /* width: 340px; */
  width: 300px;
  height: 333.56px;
  margin: 30px 0px;
  background: #f6f9fb;
  box-shadow: 0px 4px 4px rgba(74, 74, 74, 0.25);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  /*  &:hover {
    transform: scale(1.1);
  } */
  @media (max-width: 900px) {
    width: 70%;
    flex-direction: column;
    align-items: space-between;
  }
`

const CardImgStyled = styled.img`
  width: 60px;
  height: 60px;
  margin: 30px auto;
  &.animate {
    animation: fade-up 2s;
  }
`

const CardTextStyled = styled(CardText)`
  font-family: "Archivo", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150.3%;
  text-align: center !important;
  letter-spacing: 0.055em;
  color: #494949;
  @media (max-width: 900px) {
    font-size: 12px;
    padding: 0;
  }
`
const CardTitleStyled = styled(CardTextStyled)`
  font-weight: 600;
  font-size: 22px;
  /* color: #303030; */
  color: #494949;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 0;
  }
`

function DetailsCard({ title, text, image }) {

 
  return (
    <>
      <CardStyled
      >
        <CardImgStyled src={image} alt={title} />
        <CardBody>
          <CardTitleStyled>{title}</CardTitleStyled>
          <CardTextStyled>{text}</CardTextStyled>
        </CardBody>
      </CardStyled>
    </>
  )
}

export default DetailsCard
