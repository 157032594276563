import React from "react"
import styled from "styled-components"

const Container = styled.div`
  justify-content: center;
  background: #fff;
  margin: 30px 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  @media (max-width: 900px) {
    margin: 10px 10px 10px 10px;
  }
`

const Img = styled.img`

  @media (max-width: 900px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 500px) {
    width: 250px;
    height: 250px;
  }
`

function SponsorCard(props) {
  return (
    <Container
     
    >
      <Img src={props.image} alt={props.name} width="250" height="250" />
    </Container>
  )
}

export default SponsorCard
