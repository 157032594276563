import React, { useState, useEffect, useRef } from "react"

//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import Picture from "../components/Picture"
import Intro from "../components/Intro"
import Details from "../components/Details"
import SponsorsList from "../components/SponsorsList"
import SponsorMail from "../components/SponsorMail"
import useContentful from "../utils/useContentful"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(250px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const Title = styled.h1`
  /* font-family: "Archivo", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 150.3%;
  display: flex;
  letter-spacing: 0.055em;
  color: #303030;
  margin-left:5rem ;

  &.animate {
    animation: fade-up 2s;
  }

  @media (max-width: 700px) {
    font-size: 20px;
    width: 100%;
    margin-left:2rem ;
  }
`

const SponsorEntryId = "1lKctkI9uOTAEFW1PjDyR7";

export default function Sponsors() {

  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 1150) {
        setShow(true);
      } else {
        setShow(false);
      }}else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { data: sponsor, fetched } = useContentful({ id: SponsorEntryId });






  return (
    <>

      <Helmet>
   <link
              rel="alternate"
              hreflang="es-pe"
              href="https://www.datascienceglobalskills.pe/sponsors"
            />
            <link rel="canonical" href="https://www.datascienceglobalskills.pe/sponsors" />

   </Helmet>
    
    {fetched ? <MainLayout active={4}>
      <Picture image={sponsor.fields.hero.fields.file.url}>
      
      </Picture>
{sponsor.fields.content.map((item) => {

  return(
    <>
{item.fields.title && <Title className={show ? "animate" : ""}>
{item.fields.title}
</Title>}
{item.fields.text && <Intro>
       {item.fields.text}
      </Intro>}
      {item.fields.content.map((subitem)=>{
        if (subitem.sys.contentType.sys.id == "sponsorIntroCards"){

          return(
            <Details data={subitem.fields.content}/>
          )
        }
        if (subitem.sys.contentType.sys.id == "sponsorIntroLogos"){
          return(
            <SponsorsList data={subitem.fields.image}/>
          )
        }
      })
}
      </>
  )
})}





     {/* <Intro fontSize="28px">
        Acompaña nuestra misión, financiando becas para que un talento peruano
        se convierta en un especialista en ciencia de datos y pueda potenciar su
        carrera y el desarrollo del país.
        <br></br>
        <br></br>
        Además, tu empresa accederá a nuestros beneficios especiales:
      </Intro>
      <Details />
      <SponsorsList />*/}
  
    </MainLayout> :
    <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      marginTop: "0px",
      padding: "15%",
    }}
  >
    {" "}
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBlackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="#008991"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
}
</>
  )
}
