import React, { useState, useEffect, useRef } from "react"
import DetailsCard from "./DetailsCard"
import "bootstrap/dist/css/bootstrap.min.css"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
@keyframes fade-up {
  
  0%{
    opacity: 0;
    transform: translateY(300px) scale(0.9);
  }
  
  100%{
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  
  }
  
`;

const Wraper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap:16px;

  &.animate {
    animation: fade-up 1s;
  }


  @media (max-width: 900px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
`
const Colu = styled(Col)`
  position: relative;
  // top: -100px;
  margin-bottom: 3%;
  @media (max-width: 700px) {
    width: 100%;
  }
`

function Details({data}) {


  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if(window.screen.width > 600){
      if (window.scrollY > 350) {
        setShow(true);
      } else {
        setShow(false);
      }}
      else{
        setShow(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <>
      <Row style={{ padding: "20px 0px 0px 0px", margin: "0px" }}>
        <Colu xs="12" sm={{ size: 10, offset: 1 }}>
          <Wraper className={show ? "animate" : ""}>
            {data.map(detail => (
              <DetailsCard image={detail.fields.icon.fields.file.url} title={detail.fields.title} text={detail.fields.text} />
            ))}
          </Wraper>
        </Colu>
      </Row>
    </>
  )
}

export default Details
